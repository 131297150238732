@import 'styles/theme';
.cpc {
  &__modal {
    &_files {
      display: flex;
      flex-direction: column;
      &-label {
        margin-bottom: 10px;
      }
    }
  }
  &__checkbox {
    margin-bottom: 10px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 44px 0 30px;
  }
  &__actions {
    display: flex;

    &_save {
      margin: 0 10px;
    }
  }
  &__block {
    padding: 30px;
    background: $other-white;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    margin-bottom: 30px;
    min-height: 200px;
    position: relative;
  }
  &__value {
    width: 400px;
    margin-bottom: 20px;
  }
  &__subtitle {
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 20px;
  }

  &__row {
    background: $other-white;
    border: 1px solid $secondary-5;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 20px 0 20px 45px;
    position: relative;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__input {
    width: 573px;
    margin-bottom: 10px;
  }

  &__checkbox {
    margin: 15px 0;
  }

  &__notice {
    position: absolute;
		z-index: 1;
		top: -55px;
		background: $other-white;
		box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
		border-radius: 6px;
		padding: 15px;
		white-space: pre;
    pointer-events: none;
    color: $secondary-80;
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
  }

  &__action {
    width: 213px;
    background: $secondary-5;
    margin-top: 10px;
    height: 32px;

    &_block {
      width: 213px;
      position: relative;
    }
    &_notice {
      position: absolute;
      right: 0px;
      top: 5px;
    }
  }

  &__draggable {
    position: absolute;
    left: 10px;
    top: 15px;
    cursor: move;
  }

  &__actions {
    display: flex;
    margin-top: 20px;
    &_add {
      margin-right: 10px;
    }
    &_remove {
      width: 305px;
      height: 40px;
      background: $secondary-5;
    }
  }

  &__files {
    display: flex;
    flex-direction: column;
  }

  &__file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    & + label {
      font-size: 1em;
      width: 116px;
      font-weight: 500;
      background-color: $base-10;
      color: $base-80;
      display: inline-block;
      cursor: pointer;
      transition: 0.2s ease;
      padding: 14px 9px;
      margin-bottom: 10px;
    }

    &:focus + label,
    & + label:hover {
      color: $base-60;
    }

    &:focus + label {
      outline: 1px dotted #000;
    }

    &_info {
      transition: 0.2s ease;
      margin-bottom: 10px;
    }

    &_buttons {
      display: flex;
    }

    &_save {
      margin-right: 10px;
    }

    &_h {
      margin-bottom: 10px;
    }

    &_img {
      width: 230px;
      object-fit: cover;
      height: 135px;
      margin-bottom: 10px;
    }
  }
}