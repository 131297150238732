@import 'styles/theme.scss';

.header {
  width: 100%;
  background-color: $other-white;
  z-index: 1;

  &_unauthorized {
    background-color: $base-10;
  }

  &__container {
    height: 104px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
  }

  &__logo {
    width: 175px;
    height: 44px;
    flex-shrink: 0;

    &_svg {
      width: 160px;
      height: 50px;
    }

    &_admin {
      display: flex;
      align-items: flex-end;

      &-post {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;
        color: $base-60;
        margin-left: 5px;
      }
    }
  }

  &__divider {
    width: 1px;
    height: 64px;
    background-color: $secondary-5;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    height: inherit;
    flex-grow: 1;
    &::before {
      width: 1px;
      height: 64px;
      background-color: $secondary-5;
      margin: 0 40px;
    }
  }
  &__menu {
    display: flex;
    align-items: center;
  }

  &__nav {
    display: flex;
    gap: 40px;

    &_item {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 24px;
      color: $base-60;
      text-decoration: none;
      transition: 0.2s;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: transparent;
        left: 0;
        bottom: 0;
        transition: 0.2s;
      }

      &-active {
        color: $base-80;
        pointer-events: none;
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: $base-80;
          left: 0;
          bottom: 0;
          transition: 0.2s;
        }
      }

      &:hover {
        color: $base-80;
      }
    }
  }
  &__submenu {
    width: 100%;
    height: 50px;
    background-color: $base-10;
    display: flex;
  }

  &__personal {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__profile {
    margin-left: 22px;
    position: relative;

    &_head {
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    &_avatar {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      background-color: $base-60;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $other-white;
      // font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      user-select: none;
    }

    &_info {
      margin: 0 10px;
    }

    &_email {
      padding-top: 10px;
      color: $secondary-80;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 2px;
    }

    &_role {
      color: $secondary-40;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
    }

    &_arrow {
      background-color: $secondary-2;
      border-radius: 12px;
      width: 24px;
      height: 24px;
      padding: 2px;
      transition: transform 0.2s ease;
      color: $secondary-40;
    }

    &_opened &_arrow {
      transform: rotate(180deg);
    }

    &_more {
      min-width: 286px;
      background-color: $other-white;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      padding: 20px;
      text-align: center;
      position: absolute;
      right: 0;
      top: 100%;
      z-index: 1;
      margin-top: 3px;
      &-link {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
      }
    }

    &_more &_avatar {
      width: 60px;
      height: 60px;
    }

    &_line {
      background-color: $secondary-5;
      width: 100%;
      height: 1px;
      margin: 20px 0;
    }

    &_button {
      margin: 0 auto;
    }

    &_photo {
      width: 60px;
      height: 60px;
      margin-bottom: 10px;

      & + .header__profile_email {
        margin-bottom: 2px;
      }
    }
  }
}
