@import 'styles/theme';
.action {
  width: inherit;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $other-white;
  border-radius: 4px;
  padding: 6px 10px;
  font-family: $Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  &__icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  &_edit {
    color: $base-80;
    transition: all 0.2s ease;

    &:hover {
      background: $secondary-20;
    }
  }
  &_remove {
    color: $other-red;
    transition: all 0.2s ease;

    &:hover {
      background: $other-red-10;
    }
  }
  &_remove-category {
    color: $other-red;
  }
  &_disabled {
    pointer-events: none;
    opacity: 0.5
  }
}
