@import 'styles/theme';

.error {
  &__title {
    text-align: center;
    color: $other-red;
  }
  &__okey {
    margin: 40px auto 0;
  }
}