@import 'styles/theme.scss';

.new_leave {
	display: flex;
	flex-direction: column;
	gap: 20px;

    &__icon {
        text-align: center;
        margin-bottom: 30px;
    }

	&__title {
		font-size: 24px;
        line-height: 30px;
        font-weight: 600;
        margin-bottom: 60px;
        white-space: pre-line;
        text-align: center;
	}

	&__form {
		display: flex;
		flex-direction: column;
		gap: 20px;
		border-bottom: 1px solid $secondary-5;
	}

	&__buttons {
		display: flex;
		gap: 10px;
		justify-content: center;
        margin-top: 20px;
	}
}
