@import 'styles/theme.scss';

.button {
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  user-select: none;

  cursor: pointer;
  transition: 0.2s ease;
  border-radius: 4px;

  &_size {
    &_lg {
      padding: 12px 22px;
    }

    &_md {
      padding: 10px 20px;
    }

    &_sm {
      padding: 8px 16px;
    }

    &_xs {
      padding: 7px 12px;
    }
  }

  &_disabled,
  &:disabled {
    background-color: $secondary-10;
    color: $secondary-30;
    pointer-events: none;
  }
  &_theme {
    &_primary {
      color: $other-white;
      background-color: $base-80;
      &:hover {
        background-color: $base-60;
      }
      &:active {
        background-color: $base-100;
      }
    }

    &_secondary {
      background-color: $base-10;
      color: $base-80;
      &:hover {
        color: $base-60;
      }
      &:active {
        color: $base-100;
      }
    }
    &_tetriary {
      background-color: $secondary-5;
      color: $base-80;
      &:hover {
        background-color: $secondary-20;
      }
      &:active {
        background-color: $secondary-20;
        color: $base-100;
      }
    }

    &_other-yellow {
      background-color: $other-yellow;
      color: $other-white;
      &:hover {
        background-image: linear-gradient(180deg, $other-yellow 0%, #ffd600 100%);
      }
    }

    &_other-red {
      background-color: $other-white;
      color: $other-red;
      &:hover {
        background-color: $other-red;
        color: $other-white;
      }
    }

    &_other-blue {
      background-color: $other-white;
      color: $base-80;
      &:hover {
        background-color: $base-80;
        color: $other-white;
      }
    }

    &_other-green {
      background: $other-white;
      color: $other-green;
      &:hover {
        background: $other-green;
        color: $other-white;
      }
    }
  }

  &__plus,
  &__chevron {
    width: 20px;
    height: 20px;
  }

  &__plus {
    margin-right: 9px;
  }

  &__chevron {
    transform: translateX(-5px) rotate(90deg);
  }

  &_other-white {
    background: $other-white;
    border: 1px solid transparent;
    color: $base-80;
    transition: 0.2s;
    opacity: 0.8;

    &:hover {
      border: 1px solid #546b82;
      background: $other-white;
    }
  }
}
