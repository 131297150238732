@import 'styles/theme.scss';

.input {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 14px;
  line-height: 20px;

  &__field {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__icon {
    color: $secondary-40;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__view {
    cursor: pointer;
    transition: opacity 0.3s;
    &_hidden {
      opacity: 0;
    }
  }

  &__search {
    background-size: cover;
  }

  &__label {
    display: flex;
    justify-content: space-between;
    color: $secondary-100;
    margin-bottom: 4px;
    &_error {
      color: $other-red;
    }
  }

  &__info {
    &_pass {
      color: $base-60;
      cursor: pointer;
    }
    &_length {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: $secondary-30;
      &_maxed {
        color: $other-red;
      }
    }
    &_counter {
      width: 20px;
      height: 20px;
      font-weight: 600;
      font-size: 11px;
      line-height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $other-white;
      background-color: $secondary-30;
      border-radius: 10px;
    }
  }

  &__item {
    text-overflow: ellipsis;
    padding: 12px 15px;
    font-weight: 500;
    background-color: $other-hwhite;
    border: 1px solid $secondary-30;
    box-sizing: border-box;
    border-radius: 6px;
    outline: none;
    transition: 0.2s;
    position: relative;
    pointer-events: all;

    &_size {
      &_lg {
        padding: 11px 14px;
      }
      &_md {
        padding: 9px 11px;
      }
    }

    &_icon {
      padding-right: 40px;
    }

    &::placeholder {
      color: $secondary-30;
      transition: 0.2s;
    }

    &:focus {
      border: 1px solid $base-60;
    }

    &:disabled {
      background-color: $secondary-5;
      border: 1px solid $secondary-20;
    }

    &_filled {
      border: 1px solid $base-80;
    }

    &_error {
      border: 1px solid $other-red;
      &_label::placeholder {
        color: $other-red;
      }
      &_label:focus::placeholder {
        color: $secondary-30;
      }
    }
  }

  &__required {
    margin-left: 4px;
    color: $other-red;
  }
}
