@import 'styles/theme.scss';

.expanded_picture {
	position: relative;
	min-width: 36px;
	width: 36px;
	height: 36px;
	&__container {
		width: 100%;
		height: 100%;
		background-color: $secondary-20;
		border-radius: 50%;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__preview {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	&__full {
		width: 250px;
		height: 250px;
		position: absolute;
		top: 0px;
		left: calc(100% + 5px);
		object-fit: contain;
		background: $other-white;
		z-index: 1;
		border-radius: 6px;
		box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
	}
	&__stub {
		width: 16px;
		height: 16px;
		color: $other-white;
	}
}
