@import 'styles/theme';

.categories {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
  }
  &__filters {
    display: flex;
    align-items: flex-end;
    margin-top: 32px;
    height: 64px;

    &_input {
      width: 427px;
      margin-right: 20px;
    }

    &_find {
      margin-right: 10px;
    }
  }

  &__block {
    padding: 30px;
    background: $other-white;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    margin-top: 20px;
    margin-bottom: 54px;
    min-height: 200px;
    position: relative;
  }

  &__row {
    padding: 17px 20px;
    background: $secondary-5;
    border-radius: 4px;
    margin-bottom: 10px;
    text-decoration: none;
    position: relative;

    &__info {
      display: flex;
      align-items: center;
    }

    &__additional {
      border-top: 1px solid black;
      margin-left: -20px;
      margin-right: -20px;

      &-2 {
        // border-top: 1px solid black;
        padding-left: 15px;
      }

      &-ctn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
      }

      &-ctn-2 {
        display: flex;
        align-items: center;
        padding: 15px;
      }
    }

    &__logo {
      margin-right: 10px;
    }

    &__name {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $base-80;
      cursor: pointer;
      width: 320px;

      &:hover {
        color: $base-60;
        transition: all 0.2s;
      }
    }

    &-ctn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
    }

    &:hover .categories__name {
      color: $base-60;
      transition: all 0.2s;
    }

    &:last-child {
      margin-bottom: 20px;
    }
  }

  &__name {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $base-80;
    cursor: pointer;
    width: 320px;
  }

  &__modal {
    &_input {
      margin-bottom: 10px;
    }
  }

  &__desc {
    width: 320px;
  }

  &__actions {
    display: flex;
    z-index: 1;
  }

  &__remove {
    cursor: pointer;
    margin-left: 5px;
    width: 98px;
    height: 32px;
    display: block;

    &_block {
      width: inherit;
      position: relative;
    }
    &_notice {
      position: absolute;
      right: 0px;
      top: 5px;
    }
  }

  &__edit {
    cursor: pointer;
    min-width: 80px;
    height: 32px;
  }

  &__features {
    margin-right: 5px;
  }

  &__more {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0;
    background: $secondary-5;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $base-80;
    cursor: pointer;
  }

  &__link {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  &__empty {
    min-height: 460px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 92px;

    &_search {
      width: 80px;
      height: 80px;
      padding: 24px;
      background-color: $secondary-20;
      color: $other-white;
      border-radius: 50%;
    }

    &_title {
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      color: $secondary-80;
      margin: 30px 0 10px;
    }

    &_desc {
      color: $secondary-80;
    }
  }
}

.categories-features {
  &__header {
    padding: 44px 0 30px;
  }
  &__block {
    padding: 30px;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    margin-bottom: 30px;
    min-height: 200px;
    position: relative;
  }
  &__empty {
    text-align: center;
    margin-top: 60px;
    font-size: 30px;
  }
}