@import 'styles/theme';

.footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  background-color: $base-100;
  height: 80px;
  display: flex;

  &__content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
  }

  &__version {
    color: $base-80;
  }

  &__nav {
    flex-grow: 1;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;

    &_item {
      margin: 0 10px;
      position: relative;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $base-80;
      text-decoration: none;
      transition: 0.2s ease;

      &:hover {
        color: $other-white;
      }

      &-active {
        color: $other-white;
      }
      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;

        &::before {
          display: none;
        }
      }

      &::before {
        width: 3px;
        height: 3px;
        content: '';
        position: absolute;
        top: 50%;
        right: -11px;
        background: $base-80;
        border-radius: 50%;
      }
    }

    &_link {
    }
  }
}
