@import 'styles/theme.scss';
.notify {
	position: relative;
	color: $secondary-40;
	transition: color 0.3s;
	&.isOpened,
	&:hover {
		color: $base-60;
	}
	&__head {
		cursor: pointer;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	&__icon {
		min-width: 24px;
		min-height: 24px;
		position: relative;
		text-align: center;
	}
	&__counter {
		top: -8px;
		left: 13px;
		position: absolute;
		width: 20px;
		height: 20px;
		background-color: $other-red;
		border-radius: 12px;
		font-weight: bold;
		font-size: 11px;
		line-height: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $other-white;
		user-select: none;
	}
	&__text {
		font-size: 10px;
		line-height: 140%;
		user-select: none;
	}
	&__block {
		margin-top: 6px;
		position: absolute;
		right: 0;
		top: 100%;
		z-index: 1;

		width: 650px;
		height: auto;
		background-color: $other-white;
		box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
		border-radius: 6px;
		padding: 20px;
		font-size: 14px;
		line-height: 20px;
	}
	&__title {
		font-weight: 600;
		font-size: 18px;
		line-height: 26px;
		text-align: center;
		color: $secondary-80;
		padding-bottom: 20px;
	}
	&__list {
		margin-right: -20px;
		max-height: 490px;
		overflow-y: scroll;
		padding-right: 14px;
		display: flex;
		flex-direction: column;
		gap: 5px;
		&::-webkit-scrollbar-track {
			border-radius: 3px;
		}
		&::-webkit-scrollbar {
			width: 6px;
		}
		&::-webkit-scrollbar-thumb {
			border-radius: 4px;
			background-color: $secondary-30;
		}
		&_item {
			display: flex;
			background-color: $secondary-5;
			border-radius: 6px;
			cursor: pointer;
		}
		&_item_content {
			flex-grow: 1;
			display: flex;
			justify-content: space-between;
			padding: 10px;
			color: $secondary-80;
			transition: 0.1s;
			max-width: 570px;
			&:hover {
				color: $base-60;
			}
		}
		&_item_content:hover &_name {
			color: $base-60;
		}
		&_delete {
			width: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: $secondary-40;
			transition: color 0.1s;
			&_icon {
				width: 20px;
				height: 20px;
			}
			&:hover {
				color: $other-red;
			}
		}
		&_icon {
			min-width: 20px;
			max-height: 20px;
			margin-right: 12px;
		}
		&_new {
			min-width: 8px;
			min-height: 8px;
			background-color: $other-red;
			border-radius: 50%;
			margin-right: 8px;
			transition: opacity 0.3s;

			&_viewed {
				opacity: 0;
			}
		}
		&_action {
			margin-right: 5px;
		}
		&_name {
			transition: 0.3s;
			font-weight: 500;
			color: $secondary-100;
		}
		&_date {
			color: $secondary-80;
		}
		&_column {
			display: flex;
			align-items: center;
		}
		&_more {
			font-weight: 500;
			color: $base-80;
			text-align: center;
			padding: 10px;
			background-color: $base-10;
			border-radius: 4px;
			cursor: pointer;
		}
	}
	&__empty {
		text-align: center;
		padding: 40px 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		&_title {
			font-weight: 600;
			font-size: 18px;
			line-height: 26px;
			color: $secondary-80;
			margin-top: 20px;
		}
		&_icon {
			width: 80px;
			height: 80px;
			padding: 24px;
			color: $other-white;
			background-color: $secondary-20;
			border-radius: 50%;
		}
	}
}
