@import 'styles/theme';

.reviews {
  &__top {
    margin: 40px 0 30px;
  }

  &__table_vendor {
    color: $base-60;
    &:hover {
      text-decoration: underline;
    }
  }

  &__table_vendor {
    &_deleted {
      color: $other-red;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
    }
    &_disabled {
      color: rgba(102, 177, 242, 0.5);
      pointer-events: none;
    }
  }
}
