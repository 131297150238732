@import 'styles/theme.scss';

.confirm {
	padding-top: 20px;
	display: flex;
	flex-direction: column;
	color: $secondary-80;

	&__icon {
		align-self: center;
		margin-bottom: 30px;
		padding: 24px;
		width: 80px;
		height: 80px;
		font-size: 30px;
		border-radius: 50%;
		background-color: $secondary-80;
		color: $other-white;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&_red &__icon {
		background-color: $other-red;
	}

	&_green &__icon {
		background-color: $other-green;
	}

	&_yellow &__icon {
		background-color: $other-yellow;
	}

	&__body {
		margin-bottom: 30px;
	}

	&__caption {
		margin-bottom: 30px;
		text-align: center;
	}

	&__title {
		font-size: 24px;
		line-height: 30px;
		font-weight: 600;
		margin-bottom: 10px;
		white-space: pre-line;
	}

	&__divider {
		margin-bottom: 20px;
		width: 100%;
		height: 1px;
		background-color: $secondary-5;
	}

	&__buttons {
		display: flex;
		justify-content: center;
		gap: 10px;
	}
}
