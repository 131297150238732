@import 'styles/theme.scss';

.table {
  margin-top: 20px;
  margin-bottom: 54px;
  padding: 30px;
  background: $other-white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-size: 16px;
  line-height: 24px;
  min-height: 200px;

  &__loader {
    height: 120px;
    position: relative;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &_title {
      font-weight: 600;
      font-size: 26px;
      line-height: 32px;
      color: $secondary-100;
      margin-right: 10px;
    }
    &_total {
      background: $secondary-5;
      border-radius: 13px;
      padding: 3px 10px;
      font-size: 14px;
      line-height: 20px;
      color: $secondary-80;
    }
  }

  &__head {
    display: flex;
    background: $secondary-20;
    padding: 15px 0 15px 20px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    color: $secondary-80;

    &_item {
      user-select: none;
      display: flex;
      align-items: center;
      font-weight: 600;
      margin-right: 20px;
      &.sortable {
        cursor: pointer;
        display: flex;
      }
      &:last-child {
        margin-right: 0px;
      }
    }

    &_sort_icon {
      width: 24px;
      height: 24px;
      margin-left: 5px;
      color: $secondary-100;
      margin-top: -24px;

      & .sorted {
        color: $base-60;
      }

      &-up {
        width: 20px;
        height: 20px;
        margin-bottom: -10px;
      }

      &-down {
        width: 20px;
        height: 20px;
        transform: rotate(180deg);
        margin-top: -10px;
      }
    }
    &_item.sorted & {
      &_sort_icon {
        &-up, &-down {
          color: $base-60;
        }
      }
    }
  }

  &__empty {
    min-height: 460px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 92px;

    &_search {
      width: 80px;
      height: 80px;
      padding: 24px;
      background-color: $secondary-20;
      color: $other-white;
      border-radius: 50%;
    }

    &_title {
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      color: $secondary-80;
      margin: 30px 0 10px;
    }

    &_desc {
      color: $secondary-80;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 5px 0;
    &_scrollable {
      overflow-y: scroll;
      // max-height: calc(100vh - 583px);
      max-height: 585px;
      min-height: 200px;
      padding-right: 19px;
      margin-right: -25px;
      margin-bottom: 15px;
      &::-webkit-scrollbar-track {
        background-color: $secondary-2;
        border-radius: 3px;
      }
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: $secondary-30;
      }
    }
  }
  &__show_all {
    padding-top: 15px;
    display: flex;
    flex-direction: column;
  }
}
