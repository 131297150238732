@import 'styles/theme.scss';

.password_requipments {
	&__list {
		font-size: 14px;
		line-height: 20px;
	}
	&__item {
		display: flex;
		align-items: center;
		cursor: default;
		transition: color 0.3s;
		position: relative;
		&::before {
			content: '';
			display: inline-block;
			width: 6px;
			height: 6px;
			border-radius: 50%;
			margin-right: 5px;
			background-color: $secondary-20;
		}
		&_satisfied {
			color: $other-green;
			&::before {
				background-color: currentColor;
			}
		}
		&_notice {
			position: absolute;
			z-index: 1;
			top: -32px;
			background: $other-white;
			box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
			border-radius: 6px;
			padding: 5px;
			white-space: pre;
			pointer-events: none;
			color: $secondary-80;
			font-size: 12px;
			line-height: 24px;
			font-weight: normal;
		}
	}
	&_red & {
		&__list {
			color: $other-red;
		}
		&__item::before {
			background-color: currentColor;
		}
	}
}
