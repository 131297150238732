@import 'styles/theme.scss';

.admins {
  &__top {
    display: flex;
    justify-content: space-between;
    padding: 40px 0px 32px;
  }
  &__filters {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: 40px;
    &_inputs {
      display: flex;
      align-items: flex-end;
      height: 40px;
    }
    &_text {
      width: 300px;
      margin-right: 10px;
    }
    &_buttons {
      display: flex;
    }
    &_button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0px;
      }
    }
    &_role {
      width: 115px;
    }
    &_checkbox {
      margin-left: 22px;
      margin-right: 22px;
      height: 100%;
      display: flex;
    }
  }

  &__table_role {
    min-width: 200px;
    max-width: 100%;
  }
  &__table_name {
    cursor: pointer;
    &:hover {
      color: $base-60;
    }
  }
}
