@import 'styles/theme.scss';

.checkbox {
  display: flex;
  align-items: center;
  color: $secondary-80;
  font-family: $Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  &__label {
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
  }

  &__icon {
    color: transparent;
    width: 20px;
    height: 20px;
    background: $other-white;
    border: 1px solid $base-80;
    box-sizing: border-box;
    border-radius: 4px;
  }

  &__native {
    display: none;
  }

  &__desc {
    user-select: none;
  }

  &.checked & {
    &__icon {
      background: $base-80;
      color: $other-white;
    }
  }

  &.disabled & {
    &__label {
      pointer-events: none;
    }
    &__icon {
      background: $secondary-10;
      color: $secondary-30;
      border: 1px solid $secondary-20;
    }
  }
}
