@import 'styles/theme';

.buyers {
  &__top {
    padding: 40px 0 30px;
  }
  &__filters {
    display: flex;
    align-items: flex-end;
    height: 40px;
    &_actions {
      display: flex;
      margin-left: 222px;
    }
    &_find {
      margin-right: 10px;
    }
    &_input {
      width: 180px;
      margin-right: 20px;
    }
    &_dropdown {
      width: 226px;
    }
    &_role {
      width: 107px;
    }
    &_blacklist {
      width: 88px;
    }
    &_deleted {
      width: 85px;
    }
    &_checkbox {
      height: 100%;
      display: flex;
      margin-left: 20px;
    }
  }
  &__table {
    &_vendor_role {
      width: 24px;
      height: 24px;
      color: $base-80;
      background-color: $other-white;
      border-radius: 50%;
    }
    &_user {
      max-width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      &_name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}
