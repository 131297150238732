@import 'styles/theme';

.countries {
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 44px 0 30px;
  }
  &__block {
    padding: 30px;
    background: $other-white;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    margin-bottom: 30px;
    min-height: 200px;
    position: relative;

    &__ctn {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }

  &__modal {
    &_item {
      margin: 10px 0;
    }
    &_image {
      display: block;
      width: 200px;
      height: 200px;
      object-fit: cover;
      margin-bottom: 10px;
    }
  }
}

.country {
  &__item {
    display: block;
    width: 31.335%;
    margin: 0 1%;
    background: $other-white;
    border: 1px solid $secondary-5;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 20px 0 20px;
    position: relative;
    margin-bottom: 20px;
  }
  &__logo {
    display: block;
    width: 120px;
    height: 120px;
    margin: 0 auto;
    border: 1px solid $secondary-5;
  }
  &__name,
  &__desc {
    padding: 5px 30px;
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
    padding: 20px 30px;
  }
  &__actions {
    display: flex;

    &_save {
      margin-right: 10px;
    }
  }
  &__checkbox {
    padding: 10px 30px;
  }
  &__action-ctn {
    display: flex;
    justify-content: flex-end;
  }
  &__action {
    margin: 10px 30px !important;
    width: 155px !important;
  }
}