@import 'styles/theme.scss';

.multiselect {
	position: relative;
	font-size: 14px;
	line-height: 20px;
	color: $secondary-80;

	&__head {
		display: flex;
		align-items: center;
		gap: 5px;

		width: 100%;
		padding: 9px 19px;
		border-radius: 6px;

		background-color: $other-hwhite;
		border: 1px solid $secondary-30;

		font-weight: 400;
		white-space: nowrap;
		user-select: none;

		cursor: pointer;
	}
	&__label {
		opacity: 0.8;
	}
	&__selected {
		opacity: 0.8;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		flex-grow: 1;
	}
	&__arrow {
		flex-shrink: 0;
		width: 20px;
		height: 20px;
		transition: 0.1s ease;
		margin-right: -5px;
	}
	&__menu {
		position: absolute;
		left: 0;
		margin-top: 5px;
		min-width: 100%;
		border-radius: 6px;
		z-index: 1;
		background-color: $other-white;
		box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
		width: 260px;
		padding: 20px;
	}
	&__tag {
		margin-bottom: 20px;
		width: 102px;
		background-color: $secondary-5;
		border-radius: 13px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 3px 12px;
		cursor: pointer;
	}
	&__item {
		margin-bottom: 18px;

		&:last-child {
			margin-bottom: 0px;
		}
	}
	&__button {
		margin-top: 24px;
	}

	&_selected & {
		&__head {
			border-color: $base-80;
		}
		&__selected {
			color: $secondary-100;
			font-weight: 500;
		}
	}

	&_opened &,
	&:focus-within & {
		&__head {
			border-color: $base-60;
		}

		&__arrow {
			color: $base-60;
			transform: rotate(180deg);
		}
	}

	&_disabled & {
		&__head {
			pointer-events: none;
			color: $secondary-30;
			background-color: $secondary-5;
			border-color: $secondary-20;
		}
		&__arrow {
			color: $secondary-20;
		}
	}
}
