@import 'styles/theme.scss';

.backdrop {
	//TODO: сделать нормально
	z-index: 2;
	opacity: 1;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	backdrop-filter: blur(1px);
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	background-color: rgba(#000000, 0.4);
	transition: opacity 0.3s;
	&.hidden {
		opacity: 0;
		pointer-events: none;
	}
}

.body {
	width: 650px;
	max-height: 900px;
	overflow-y: auto;
	padding: 40px;
	position: relative;
	min-width: 100px;
	min-height: 100px;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);

	&::-webkit-scrollbar-track {
		border-radius: 3px;
	}
	&::-webkit-scrollbar {
		width: 6px;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: $secondary-30;
	}
}

.close_button {
	position: absolute;
	width: 24px;
	height: 24px;
	top: 5px;
	right: 5px;
	cursor: pointer;
	color: $secondary-40;
}
