@import 'styles/theme.scss';

.table__pagination {
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
	font-family: $Manrope;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: $base-80;

	&_count {
		display: flex;
		align-items: center;
	}

	&_desc {
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		color: $secondary-80;
	}

	&_values {
		margin-left: 15px;
		display: flex;
		gap: 5px;
	}

	&_button {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 44px;
		height: 44px;
		background-color: $secondary-5;
		border-radius: 4px;
		transition: color 0.3s, background-color 0.3s;

		&:not(.active):not(.disabled) {
			cursor: pointer;

			&:hover {
				background-color: $secondary-20;
			}
		}

		&.disabled {
			color: $secondary-30;
			pointer-events: none;
		}

		&.active {
			background-color: $base-100;
			color: $other-white;
			pointer-events: none;
		}
	}

	&_action &_button {
		padding: 12px;
	}

	&_action {
		display: flex;
		align-items: center;
		gap: 5px;

		&_prev {
			transform: rotate(90deg);
		}
		&_next {
			transform: rotate(-90deg);
		}
		&_last {
			transform: rotate(180deg);
		}

		&_pages {
			background: $secondary-5;
			border-radius: 4px;
			padding: 10px;

			&_total {
				color: $secondary-30;
			}
		}
	}
}
