@import 'styles/theme';

.datepicker {
  position: relative;
  font-size: 14px;
  line-height: 20px;

  &__head {
    display: flex;
    align-items: center;
    gap: 5px;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    background-color: $other-hwhite;
    padding: 9px 14px 9px 19px;
    border: 1px solid $secondary-30;
    color: $secondary-80;
  }
  &__label {
    opacity: 0.8;
    white-space: nowrap;
  }
  &__selected {
    opacity: 0.8;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex-grow: 1;
  }
  &__arrow {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    color: $secondary-80;
    transition: 0.1s ease;
  }
  &__content {
    position: absolute;
    z-index: 1;
    min-width: 288px;
    padding: 20px;
    margin-top: 8px;
    background-color: $other-white;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
  }
  &__tags {
    display: flex;
  }
  &__tag {
    padding: 3px 12px;
    margin-right: 5px;
    background-color: $secondary-5;
    border-radius: 4px;
    font-weight: 500;
    color: $secondary-80;
    cursor: pointer;

    &-active {
      background-color: $base-60;
      color: $other-white;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__period {
    margin-top: 20px;
    &_title {
      color: $secondary-100;
      &-req {
        color: $other-red;
      }
    }
    &_fields {
      display: flex;
      justify-content: space-between;
      margin-top: 4px;
      margin-bottom: 15px;
      gap: 20px;
    }
    &_field {
      width: 100%;
      background-color: $other-white;
      border-radius: 6px;
      border: 1px solid $secondary-30;
      padding: 11px 14px;
      &:disabled {
        background-color: $secondary-10;
        border-color: $secondary-20;
      }
    }
    &_dpcontainer {
      position: relative;
    }
    &_calendar {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.selected & {
    &__head {
      border-color: $base-80;
    }
    &__selected {
      color: $secondary-100;
      font-weight: 500;
    }
  }

  &.opened &,
  &:focus-within & {
    &__head {
      border-color: $base-60;
    }

    &__arrow {
      color: $base-60;
      transform: rotate(180deg);
    }
  }

  &.disabled & {
    &__head {
      pointer-events: none;
      color: $secondary-30;
      background-color: $secondary-5;
      border-color: $secondary-20;
    }
    &__arrow {
      color: $secondary-20;
    }
  }
}
