@import 'styles/theme';

.vendors-item {
  margin-bottom: 60px;
  &__content {
    display: flex;
    justify-content: space-between;
  }
  &__back {
    margin: 20px 0 10px;
    display: flex;
  }
  &__title_desc{
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    align-items: center;
  }
  &__info {
    width: 873px;
    background: $other-white;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    padding: 30px;
  }
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  &__title {
    display: flex;
    align-items: center;
  }
  &__show {
    display: flex;
    align-items: center;
    p {
      margin-right: 10px;
    }
  }
  &__name {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    color: $secondary-100;
  }
  &__rating {
    margin-left: 32px;
    display: flex;
    &-smile {
      margin-right: 10px;
    }
  }
  &__day{
    width: 130px;
  }
  &__hours {
    &_row {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
    &_checkbox {
      margin-right: 17px;
    }
    &_slash {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      margin: 0 5px;
      color: $secondary-40;
    }
    &_time {
      background: $secondary-10;
      border: 1px solid $secondary-30;
      box-sizing: border-box;
      border-radius: 6px;
      padding: 5px;
      min-width: 105px;
      width: 105px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 5px 5px 15px;
    }
    &_nums {
      margin-right: 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $secondary-30;
    }
    &_cicle {
      padding: 6px 8px;
      background-color: $secondary-2;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: $secondary-20;
    }
  }
  &__line {
    width: 100%;
    height: 1px;
    background: $secondary-5;
    margin: 30px 0;
  }
  &__text {
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    color: $secondary-100;
  }
  &__status {
    margin-left: 15px;
  }
  &__premium {
    &_row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    &_legal,
    &_shopname,
    &_gst,
    &_address {
      width: 396px;
    }

    &_keywords {
      &_text {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $secondary-100;

        span {
          color: $other-red;
        }
      }
    }
    &_words {
      background: $secondary-10;
      border: 1px solid $secondary-20;
      box-sizing: border-box;
      border-radius: 6px;
      padding: 5px 10px;
      height: auto;
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      min-height: 44px;

      span {
        background: $secondary-2;
        border-radius: 4px;
        padding: 3px 9px;
        margin-right: 10px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $secondary-30;
        margin: 4px 10px 4px 0px;
      }
    }
  }

  &__more {
    margin-top: 40px;
    &_row {
      margin-top: 20px;
    }
    &_title {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      color: $secondary-80;
      margin-bottom: 20px;
    }
    &_desc {
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $secondary-100;
        margin-top: 4px;
      }
      &-main {
        background: $base-10;
        border: 1px solid $base-60;
        box-sizing: border-box;
        border-radius: 6px;
        padding: 12px 0px 12px 15px;
        position: relative;
        min-height: 64px;
        p {
          width: 707px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $secondary-80;
          margin-top: 0;
        }
      }
      &-noapp {
        background: $secondary-10;
        border: 1px solid $secondary-20;
        box-sizing: border-box;
        border-radius: 6px;
        padding: 12px 15px;
        color: $secondary-80;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }
      &-req {
        position: absolute;
        top: 6px;
        right: 6px;
        width: 75px;
        height: 32px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

  &__products {
    margin: 40px 0 20px;
    &_title {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      color: $secondary-80;
      margin-bottom: 20px;
      span {
        color: $other-red;
      }
    }

    &_block {
      background: $other-white;
      border: 1px solid $secondary-5;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 20px;
      margin-bottom: 10px;
    }

    &_subtitle {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      color: $secondary-80;
      margin-bottom: 20px;
    }

    &_minititle {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $secondary-40;
      margin-bottom: 10px;
    }

    &_row {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      &_container {
        width: 380px;
        margin-bottom: 10px;
      }
    }
  }

  &__cover {
    width: 427px;
    background: $other-white;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    padding: 30px;
    margin-bottom: 20px;
    &_title {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      color: $secondary-80;
      margin-bottom: 20px;
      span {
        color: $other-red;
      }
    }
    &_photo {
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 367px;
      height: 367px;
      background-color: $secondary-5;
      overflow: hidden;
    }
    &_stub {
      width: 60px;
      height: 60px;
      color: $secondary-20;
    }
    &_image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__address {
    &_item {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__payments {
    &_title {
      margin-bottom: 10px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $secondary-40;
    }
    &_method {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
