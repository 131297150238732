@import 'styles/theme.scss';

html {
  font-family: $Manrope;
}

body {
  background-color: $secondary-2;
  position: relative;
}

.container {
  max-width: 1320px;
  margin: 0 auto;
  width: 100%;
}

.title {
  font-family: $Manrope;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: $secondary-100;
}

* {
  box-sizing: border-box;
}
