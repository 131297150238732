/* manrope-regular - latin_cyrillic */
@font-face {
	font-family: 'Manrope';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/manrope-v8-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
	src: local(''), url('../fonts/manrope-v8-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('../fonts/manrope-v8-latin_cyrillic-regular.woff2') format('woff2'),
		/* Super Modern Browsers */ url('../fonts/manrope-v8-latin_cyrillic-regular.woff') format('woff'),
		/* Modern Browsers */ url('../fonts/manrope-v8-latin_cyrillic-regular.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('../fonts/manrope-v8-latin_cyrillic-regular.svg#Manrope') format('svg'); /* Legacy iOS */
}
/* manrope-500 - latin_cyrillic */
@font-face {
	font-family: 'Manrope';
	font-style: normal;
	font-weight: 500;
	src: url('../fonts/manrope-v8-latin_cyrillic-500.eot'); /* IE9 Compat Modes */
	src: local(''), url('../fonts/manrope-v8-latin_cyrillic-500.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('../fonts/manrope-v8-latin_cyrillic-500.woff2') format('woff2'),
		/* Super Modern Browsers */ url('../fonts/manrope-v8-latin_cyrillic-500.woff') format('woff'),
		/* Modern Browsers */ url('../fonts/manrope-v8-latin_cyrillic-500.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('../fonts/manrope-v8-latin_cyrillic-500.svg#Manrope') format('svg'); /* Legacy iOS */
}
/* manrope-600 - latin_cyrillic */
@font-face {
	font-family: 'Manrope';
	font-style: normal;
	font-weight: 600;
	src: url('../fonts/manrope-v8-latin_cyrillic-600.eot'); /* IE9 Compat Modes */
	src: local(''), url('../fonts/manrope-v8-latin_cyrillic-600.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('../fonts/manrope-v8-latin_cyrillic-600.woff2') format('woff2'),
		/* Super Modern Browsers */ url('../fonts/manrope-v8-latin_cyrillic-600.woff') format('woff'),
		/* Modern Browsers */ url('../fonts/manrope-v8-latin_cyrillic-600.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('../fonts/manrope-v8-latin_cyrillic-600.svg#Manrope') format('svg'); /* Legacy iOS */
}
/* manrope-700 - latin_cyrillic */
@font-face {
	font-family: 'Manrope';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/manrope-v8-latin_cyrillic-700.eot'); /* IE9 Compat Modes */
	src: local(''), url('../fonts/manrope-v8-latin_cyrillic-700.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('../fonts/manrope-v8-latin_cyrillic-700.woff2') format('woff2'),
		/* Super Modern Browsers */ url('../fonts/manrope-v8-latin_cyrillic-700.woff') format('woff'),
		/* Modern Browsers */ url('../fonts/manrope-v8-latin_cyrillic-700.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('../fonts/manrope-v8-latin_cyrillic-700.svg#Manrope') format('svg'); /* Legacy iOS */
}

$Manrope: 'Manrope', sans-serif;
