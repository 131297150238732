@import 'styles/theme';

.contacts {
  &__header {
    display: flex;
    justify-content: space-between;
    margin: 40px 0 30px;
  }
  &__actions {
    position: relative;
    display: flex;
    gap: 10px;
    &_notice {
      position: relative;
    }
  }

  &__notify {
    margin-bottom: 30px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $other-yellow;
  }

  &__block {
    padding: 30px;
    background: $other-white;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    margin-bottom: 30px;
    min-height: 200px;
    position: relative;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__column {
    width: 620px;
  }

  &__remove {
    width: 229px;
    background: $secondary-5;
    margin-top: 10px;
  }

  &__line {
    width: 100%;
    height: 1px;
    background: $secondary-5;
    margin: 20px 0 15px;
  }

  &__removeall {
    width: 171px;
    height: 40px;
    background: $secondary-5;
    margin-left: 10px;
  }
}
