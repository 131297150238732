@import 'styles/theme';

.review_text {
  align-self: stretch;
  color: $base-60;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &:focus {
    background-color: $secondary-30;
  }

  &_disabled {
    color: rgba($base-60, 0.5);
    pointer-events: none;
  }

  &:not(&_opened) &__wrapper {
    cursor: pointer;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__content {
    flex-grow: 1;
  }

  &__value {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__edit {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 2px 0;
    margin-bottom: 15px;
    & *::selection {
      background-color: rgba($other-yellow, 0.3);
    }
  }

  &__edit_text {
    position: relative;
    overflow: hidden;
    line-height: 24px;
    border-radius: 4px;
    color: $secondary-80;
    white-space: break-spaces;
    user-select: none;
    word-break: break-word;
    & i {
      font-style: normal;
      background-color: rgba($other-red, 0.3);
    }
  }

  &__select_overlay {
    padding-right: 1px;
    left: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    line-height: inherit;
    color: transparent;
    word-break: break-word;
  }

  &__more {
    margin: 5px;
    margin-right: 0;
    background-color: $other-white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__arrow {
    display: flex;
    width: 24px;
    height: 16px;
    padding: 0 4px;
    cursor: pointer;
    transition: transform 0.1s ease;
  }

  &__buttons {
    display: flex;
    gap: 5px;
  }

  &_opened & {
    &__wrapper {
      align-items: flex-start;
    }

    &__arrow {
      transform: rotate(180deg);
    }
  }
}

.review_context {
  position: absolute;
  padding: 5px 20px;
  background: $other-white;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 1;

  &__item {
    font-family: $Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $secondary-80;
    padding: 10px 0;
    cursor: pointer;
    border-bottom: 1px solid $secondary-5;
    &:hover {
      color: $base-60;
    }
    &-disabled {
      color: $secondary-30;
      cursor: default;
      pointer-events: none;
    }
    &:last-child {
      border-bottom-width: 0;
    }
  }
}
