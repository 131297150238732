@import 'styles/theme';

.vendors {
  &__top {
    padding: 40px 0 32px;
  }
  &__filters {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: 40px;
    gap: 10px;

    &_name,
    &_email {
      width: 260px;
      margin-right: 15px;
    }

    &_status {
      width: 130px;
      margin-right: 15px;
    }

    &_checkbox {
      height: 100%;
      display: flex;
      margin-bottom: 10px;
    }
    &_inputs {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      align-items: flex-end;
      margin-right: 25px;
    }
    &_find {
      margin-right: 10px;
    }
    &_buttons {
      display: flex;
    }
  }

  &__table {
    &_name {
      font-weight: 600;
      color: $base-80;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
      &-banned {
        cursor: default;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
