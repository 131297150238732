@import 'styles/theme.scss';

.table_col {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	height: 26px;
	&.expandable {
		height: auto;
	}
	&.hintOpened {
		position: relative;
	}

	&__text_wrapper {
		// cursor: help;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		max-width: 100%;
		&.disabled {
			color: $secondary-30;
		}
	}
	& &__hint {
		position: absolute;
		z-index: 1;
		top: 100%;
		margin-left: 5px;
		margin-top: 5px;
		background: $other-white;
		box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
		border-radius: 6px;
		padding: 15px;
		white-space: pre;
		&,
		& > * {
			pointer-events: none;
			color: $secondary-80;
			font-size: 16px;
			line-height: 24px;
			font-weight: normal;
		}
	}
}
