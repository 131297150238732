@import './fonts.scss';

$base-100: #242a36;
$base-80: #546b82;
$base-60: #66b1f2;
$base-10: #e1f0ff;

$secondary-100: #231f20;
$secondary-80: #58595b;
$secondary-40: #a7a5a6;
$secondary-30: #bdbcbc;
$secondary-20: #e6e7e8;
$secondary-10: #eeeeee;
$secondary-5: #f4f4f4;
$secondary-2: #f8f9fa;

$other-yellow: #f3a848;
$other-red: #ff7a7a;
$other-red-10: #ffeded;
$other-green: #5bb374;

$other-white: #ffffff;
$other-hwhite: #fefefe;
