@import 'styles/theme';

.auth {
  flex-grow: 1;
  width: 100%;
  background-color: $base-10;

  &__container {
    width: 100%;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__logo {
    width: 175px;
    height: 44px;
    margin-bottom: 30px;
  }

  &__logo {
    margin-bottom: 30px;
  }

  &__block {
    padding: 40px;
    background-color: $other-white;
  }

  &__title {
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    color: $secondary-100;
  }

  &__desc {
    line-height: 20px;
    max-width: 426px;
    margin-bottom: 20px;

    &_banned {
      text-align: center;
      margin-top: 10px;
    }

    &_link {
      cursor: pointer;
      color: $base-60;
      text-decoration: none;
    }
  }

  &__list {
    &_item {
      margin-bottom: 5px;
      margin-left: 11px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: -12px;
        transform: translateY(-50%);
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background: $secondary-20;
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  &-banned {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 20px 16px;
    border: 1px solid $other-red;
    box-sizing: border-box;
    border-radius: 6px;
    color: $other-red;

    &__icon {
      width: 24px;
      height: 24px;
    }

    &__user {
      display: flex;
      align-items: center;
    }

    &__user-icon {
      width: 44px;
      height: 44px;
      padding: 10px;
      border-radius: 50%;
      background-color: $secondary-5;
      color: $other-white;
    }

    &__info {
      margin-left: 10px;
      font-weight: 500;
    }

    &__email {
      font-size: 14px;
      line-height: 20px;
      color: $secondary-80;
    }

    &__text {
      font-size: 12px;
      line-height: 18px;
    }
  }
}

.auth-form {
  &__item {
    width: 426px;
    margin-bottom: 22px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
  &__link {
    display: block;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $base-60;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
  }
}
