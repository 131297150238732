@import 'styles/theme.scss';

.new_admin {
	display: flex;
	flex-direction: column;
	gap: 20px;

	&__title {
		align-self: center;
		margin-bottom: 10px;
	}

	&__form {
		display: flex;
		flex-direction: column;
		gap: 20px;
		padding-bottom: 30px;
		border-bottom: 1px solid $secondary-5;
	}

	&__buttons {
		display: flex;
		gap: 10px;
		justify-content: center;
	}
}
