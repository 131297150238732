@import 'styles/theme';

.pimport {
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 44px 0 30px;
  }

  &__actions {
    display: flex;

    &_save {
      margin-right: 10px;
    }
  }

  &__block {
    padding: 30px;
    background: $other-white;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    margin-bottom: 30px;
    min-height: 200px;
    position: relative;
  }

  &__file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    & + label {
      font-size: 1em;
      font-weight: 500;
      background-color: $base-10;
      color: $base-80;
      display: inline-block;
      cursor: pointer;
      transition: 0.2s ease;
      padding: 14px 9px;
      margin-bottom: 10px;
    }

    &:focus + label,
    & + label:hover {
      color: $base-60;
    }

    &:focus + label {
      outline: 1px dotted #000;
    }

    &_info {
      transition: 0.2s ease;
      margin-bottom: 10px;
    }

    &_buttons {
      display: flex;
    }

    &_save {
      margin-right: 10px;
    }
  }

  &__loading {
    margin-top: 10px;
  }

  &__result {
    &_y {
      color: $other-green;
      margin-top: 10px;
    }
    &_n {
      color: $other-red;
      margin-top: 10px;
    }
  }
}