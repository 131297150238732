@import 'styles/theme.scss';

.dropdown {
  position: relative;
  color: $secondary-100;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: inherit;

    background: #fefefe;

    border: 1px solid $secondary-30;
    box-sizing: border-box;
    border-radius: 6px;

    font-family: $Manrope;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: $secondary-80;
    user-select: none;
    cursor: pointer;

    &_size {
      &_lg {
        padding: 11px 14px;
      }
      &_md {
        padding: 9px 11px;
      }
    }

    &_value {
      font-weight: 500;
    }
  }

  &__placeholder {
    color: $secondary-30;
  }

  &__arrow {
    width: 20px;
    height: 20px;
    transition: 0.1s ease;
    color: $secondary-40;
  }

  &__menu {
    position: absolute;
    left: 0;
    margin-top: 5px;
    min-width: 100%;
    border-radius: 6px;
    z-index: 1;
    background: $other-white;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  }

  &__list {
    padding: 5px 20px;
    max-height: 290px;
    overflow: auto;
    &_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      border-bottom: 1px solid $secondary-5;
      color: $secondary-80;
      font-family: $Manrope;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;

      &-nospace {
        justify-content: flex-start;
        color: $base-80;
      }

      &-icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
      &:last-child {
        border: none;
      }
      &_active,
      &:hover {
        color: $base-60;
      }
      &_active {
        pointer-events: none;
      }
      &_col {
        width: 20px;
        height: 20px;
      }
    }
    &::-webkit-scrollbar-track {
			border-radius: 3px;
		}
		&::-webkit-scrollbar {
			width: 6px;
		}
		&::-webkit-scrollbar-thumb {
			border-radius: 4px;
			background-color: $secondary-30;
		}
  }

  &_selected & {
    &__head {
      border-color: $base-80;
    }
  }

  &_opened &,
  &:focus-within & {
    &__head {
      border-color: $base-60;
    }

    &__arrow {
      color: $base-60;
      transform: rotate(180deg);
    }
  }

  &_disabled & {
    &__head {
      color: $secondary-30;
      background: $secondary-5;
      border-color: $secondary-20;
      user-select: none;
      pointer-events: none;
    }

    &__arrow {
      color: $secondary-20;
    }
  }

  &__features {
    width: 20px;
    height: 20px;
    background: $secondary-30;
    border-radius: 10px;
    font-family: $Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $other-white;
  }
}
