@import 'styles/theme.scss';

.personal {
  &__top {
    padding: 40px 0 32px;
    display: flex;
    justify-content: space-between;
  }
  &__actions {
    display: flex;
    gap: 10px;
  }

  &__block {
    display: flex;
    justify-content: space-between;
  }

  &__column {
    width: 650px;
    padding: 30px;
    min-height: 320px;
    background: $other-white;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
    border-radius: 6px;

    &_title {
      font-family: $Manrope;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      color: $secondary-80;
      margin-bottom: 20px;
    }

    &_item {
      margin-bottom: 20px;
    }
  }

  &__list {
    font-family: $Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $secondary-80;
    &_item {
      margin-bottom: 5px;
      margin-left: 11px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: -12px;
        transform: translateY(-50%);
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background: $secondary-20;
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
