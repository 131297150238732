@import 'styles/theme';

.loader__container {
    width: 100%;
    height: inherit;
}

.loader {
    width: 48px;
    height: 48px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);

    &__main {
        animation: rotating 0.5s infinite linear;
    }

    &__bg {
        background: $other-white;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        padding: 8px;
    }
}

@keyframes rotating {
	to { transform: rotate(360deg); }
}