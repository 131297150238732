@import 'styles/theme';

.payment {
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 44px 0 30px;
  }
  &__actions {
    display: flex;
    &_notice {
      margin-right: 10px;
      position: relative;
    }
  }

  &__block {
    padding: 30px;
    margin-bottom: 70px;
    background: $other-white;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    min-height: 200px;
    position: relative;
  }

  &__row {
    background: $other-white;
    border: 1px solid $secondary-5;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 20px 0 20px 45px;
    position: relative;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__input {
    width: 573px;
  }

  &__notice {
    position: absolute;
		z-index: 1;
		top: -55px;
		background: $other-white;
		box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
		border-radius: 6px;
		padding: 15px;
		white-space: pre;
    pointer-events: none;
    color: $secondary-80;
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
  }

  &__action {
    width: inherit;
    background: $secondary-5;
    margin-top: 10px;
    height: 32px;
    &_block {
      width: 213px;
      position: relative;
    }
    &_notice {
      position: absolute;
      right: 0px;
      top: 5px;
    }
  }

  &__draggable {
    position: absolute;
    left: 10px;
    top: 15px;
    cursor: move;
    pointer-events: all;
  }

  &__actions {
    display: flex;
    margin-top: 20px;
    &_add {
      margin-right: 10px;
    }
    &_remove {
      width: 305px;
      height: 40px;
      background: $secondary-5;
    }
  }
}
